@import "styles/variables.scss";
.Close {
  justify-self: end;
  grid-column: 3/4;
  cursor: pointer;
  margin-bottom: 4px;
  margin-left: calc(100% - 28px);

  + div {
    grid-column: 1/4;
  }
}

.ButtonContainer {
  display: flex;
  gap: 16px;
}

.Button {
  width: 40px;
  height: 40px;
  border: none;
  cursor: pointer;
  border-radius: 7px;

  background: url(./send.svg) no-repeat center, $blue-primary;
}
