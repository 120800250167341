@import "styles/variables.scss";
@import "styles/mixsins";

.Tabs {
  margin-bottom: 10px;
}

.NoData {
  @include labelM;
}

.Link {
  padding: 10px 0;
  display: block;
  @include border("border-top");
  
  &:last-child {
    padding-bottom: 0;
  }
}

.Item {
  display: flex;
  align-items: flex-start;
  gap: 12px;
  
  &:hover {
    cursor: pointer;
  }
}

.Position {
  display: flex;
  flex-direction: column;
  font-weight: 500;
  font-size: $font-size-s;
  color: var(--font-color-primary);
  line-height: $line-height-base;
}

.Name {
  display: flex;
  flex-direction: column;
  min-width:0;
}

.Title {
  font-weight: 500;
  font-size: $font-size-s;
  color: var(--font-color-primary);
  line-height: $line-height-base;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.Description {
  display: flex;
  align-items: center;
  color: $grey-primary-7;

  & span {
    font-weight: 600;
    font-size: $font-size-s-2;
    line-height: $line-height-base;
    color: $blue-primary;
  }
}

.Rating {
  font-weight: 500;
  font-size: $font-size-s;
  line-height: $line-height-base;
  color: var(--font-color-primary);
  margin-left: auto;
  align-self: center;
}
