@import "styles/variables.scss";
.Root {
  width: 100%;
  position: relative;
  max-width: $desktop-size;
  margin: 40px auto 48px;
  display: flex;

  @media (max-width: 1334px) {
    max-width: 980px;
  }

  @media (max-width: 1014px) {
    margin: 40px auto;
    max-width: $index-card-size;
  }

  @media (max-width: $tablet-size) {
    margin: 24px auto;
  }

  @media (max-width: $mobile-size) {
    margin: 24px auto 0;
  }
}

.Left {
  position: absolute;
  top: 0;
  left: 0;
  width: 280px;
  flex-shrink: 0;
  margin-right: 40px;

  @media (max-width: 1334px) {
    display: none;
    margin: 0;
  }
}

.Center {
  margin-left: 320px;
  flex-grow: 1;
  max-width: $index-card-size;
  display: flex;
  flex-direction: column;
  row-gap: 24px;

  @media (max-width: 1334px) {
    margin-left: 0;
  }
}

.Right {
  width: 280px;
  flex-shrink: 0;
  margin-left: 40px;

  @media (max-width: 1014px) {
    width: 100vw;
    height: 100%;
    margin-left: 0;
    position: fixed;
    top: 0;
    left: 200vw;
    transition: left ease-in-out 300ms;
    overflow: auto;

    background: var(--background-color-secondary);
    z-index: 100;
  }
}

.OpenRight {
  composes: Right;

  @media (max-width: 1014px) {
    left: 0;
  }
}

.Selector {
  display: none;
  font-size: 16px;
  line-height: $line-height-base;
  position: relative;
  cursor: pointer;

  &::after {
    content: '';
    position: absolute;
    right: 20px;
    top: 18px;
    width: 22px;
    height: 22px;
    background-color: var(--icon-color-default);
    mask: url('../../../assets/icons/menu-right.svg') no-repeat center;
    -webkit-mask: url('../../../assets/icons/menu-right.svg') no-repeat center;
    mask-size: cover;
    -webkit-mask-size: cover;
  }

  @media (max-width: 1014px) {
    display: block;
  }
}

.MobileMenu {
  display: none;
  background: var(--background-color-secondary);
  padding: 14px 30px 14px;

  @media (max-width: 1014px) {
    display: flex;
    align-items: center;
    gap: 10px;
  }
}

.MobileMenuBack {
  width: 30px;
  height: 30px;
  background-color: var(--icon-color-default);
  mask: url('../../../assets/icons/menu-left.svg') no-repeat center;
  -webkit-mask: url('../../../assets/icons/menu-left.svg') no-repeat center;
  mask-size: cover;
  -webkit-mask-size: cover;
  cursor: pointer;
}

.MobileMenuTitle {
  font-size: $font-size-xl;
  color: var(--font-color-primary);
  line-height: $line-height-base;
}

.WidgetsContainer {
  margin: 0;

  @media (max-width: 1014px) {
    margin: 0 40px 40px;
  }
}
