@import "styles/variables.scss";
.Root {
  display: grid;
  align-items: center;
  width: 100%;
  grid-template-columns: 40px auto 1fr;
  grid-template-rows: auto auto;
  column-gap: 10px;
  row-gap: 2px;
}

.AvatarLink {
  display: contents;
}

.Avatar {
  grid-row: 1 / 3;
}

.Name {
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  margin: 0;
  grid-column: 2/3;
  text-decoration: none;
  color: var(--font-color-primary);
}

.NameLink{
  color: $black-primary;
  cursor: pointer;
  text-decoration: none;
}

.IQ {
  font-size: 12px;
  line-height: 130%;
  color: $grey-primary-7;
  margin-left: 6px;
}

.Date {
  font-size: 13px;
  line-height: 150%;
  color: $grey-primary;
  margin: 0;
  grid-column: 2 / 3;
}

.ButtonsContainer {
  justify-self: end;
  grid-column: 4/5;
  grid-row: 1/3;
}

.Updating {
  margin: 0;
  padding-left: 6px;
  font-size: 12px;
  line-height: 130%;
  color: $grey-primary;
  font-style: italic;
}