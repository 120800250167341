@import "styles/variables.scss";
@import "styles/mixsins";

.Menu {
  font-size: 14px;
  line-height: 200%;
}

.Item {
  a {
    text-decoration: none;
    color: var(--font-color-primary);
    font-size: $font-size-s;
    @include linkActions("underline");
  }
}

.List {
  margin-top: 14px;
  display: flex;
  gap: 2px;

  li:first-child > svg {
    margin-left: -8px;
  }
}

.ListSocial {
  display: flex;
  gap: 2px;
}

.ListSocialItem {
  transition: 0.3s ease;

  svg:hover {
    transition: 0.3s ease;
    fill: $blue-hover;
  }

  svg:active {
    fill: $blue-click;
  }
}

.Footer {
  margin-top: 24px;
  font-size: 12px;
  line-height: 150%;
  color: $grey-primary;
}
