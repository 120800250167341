@import "styles/variables.scss";
@import "styles/mixsins";

.NoData {
  @include labelM;
  padding-top: 0;
}

.Item {
  display: flex;
  flex-direction: column;
  padding-top: 17px;
  margin-bottom: 17px;
  @include border("border-top");

  &:last-child {
    margin-bottom: 17px;
  }
}

.Block {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}

.Logo {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 1px solid var(--border-color-default);
  margin-right: 10px;
  background-position: center;
  background-size: cover;
}

.Text {
  display: flex;
  flex-direction: column;
}

.Flag {
  height: 11px;
  margin-right: 8px;
}

.Name {
  display: flex;
  align-items: center;
  color: var(--font-color-primary);

  span {
    font-weight: 500;
    font-size: $font-size-s;
    line-height: $line-height-base;
    white-space: nowrap;
  }
}

.Statistic {
  display: flex;
  align-items: center;

  span:first-child {
    font-weight: 600;
    font-size: $font-size-s-2;
    line-height: $line-height-base;
    color: $blue-primary;
    margin-right: 7px;
  }

  span:last-child {
    font-style: normal;
    font-weight: 400;
    font-size: $font-size-s-2;
    line-height: $line-height-base;
    color: $grey-primary-7;
  }
}

.AnnounceText {
  display: flex;
  flex-direction: column;
  gap: 4px;
  font-weight: 400;
  font-size: $font-size-xs;
  line-height: $line-height-base;
  color: var(--font-color-body);
}

.Description {
  display: flex;
  align-items: center;
  gap: 4px;
  font-weight: 500;
  font-size: $font-size-xs;
  color: var(--font-color-primary);
  line-height: $line-height-base;
  padding-top: 14px;
  @include border("border-top");
  transition: $transition;

  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
}
