@import "styles/variables.scss";
@import "styles/mixsins";

.topNewsWidget {
  display: flex;
  flex-direction: column;
  padding: 20px;
  position: relative;
  margin: 0;

  @media (max-width: $mobile-size) {
    padding: 16px 16px 12px;
  }
}

.widgetTitle {
  font-size: $font-size-m;
  margin-bottom: 15px;
  color: var(--font-color-primary);
  font-weight: 500;
}

.newsList {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 12px;
}

.newsItem {
  display: flex;
  gap: 8px;
  align-items: flex-start;
}

.newsDate {
  color: $grey-primary;
  min-width: 55px;
  text-align: left;
  white-space: nowrap;
}

.newsLinkBlock{
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
}

.newsTitleLink {
  text-decoration: none;
  cursor: pointer;
  color: var(--font-color-primary);
  transition: $transition;

  &:hover {
    color: $grey-primary;
  }
}


.separator {
  width: 100%;
  height: 1px;
  @include border;
}

.showMoreContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 128px;
  margin-top: 19px;
  border: 1px solid $grey-primary-4;
  border-radius: $border-radius-base;
  transition: color $transition, fill $transition;

  &:hover {
    .ShowMoreButton {
      color: $grey-primary;
      transition: color $transition, background $transition, border $transition;
    }

    .showMoreIcon {
      cursor: pointer;
      fill: var(--icon-color-default);
      transition: fill $transition;
    }
  }
}

.ShowMoreButton {
  display: flex;
  align-items: center;
  gap: 8px;
  background: none;
  padding: 0 7px;
  height: 1.8rem;
  color: var(--font-color-primary);
  text-transform: none;
  transition: color $transition, background $transition, border $transition;

  &:hover {
    background: none;
    color: $grey-primary-4;
    border: none;
  }
  svg{
    margin-top: 2px;
  }
}

.LoadingText {
  margin-top: 10px;
  text-align: center;
  font-size: $font-size-base;
  color: var(--font-color-primary);
}

.Hidden {
  display: none;
}