@import "styles/variables.scss";
.Answer {
  font-size: 12px;
  line-height: 130%;
  cursor: pointer;
  padding: 6px 10px;
  color: $grey-primary-7;
  width: fit-content;
  margin: 0;

  &:hover {
    background-color: rgba($grey-primary, 0.2);
    border-radius: 18px;
  }
}

.ModeratorButtonsContainer {
  grid-column: 3/4;
  justify-self: end;

  button {
    padding: 6px 8px;
    height: auto;
    font-size: 11px;
  }

  + svg {
    grid-column: 4/5;
    margin-left: 8px;
  }

  + svg + [ data-ui-component = "styled-textarea" ] {
    grid-column: 1/5;
    margin-top: 6px;
  }
}
