@import "styles/variables.scss";
.Root {
  width: 100%;
  min-width: 80px;
  height: 6px;
  overflow: hidden;
  border-radius: 3px;
  background-color: #2873db;
  display: flex;
  justify-content: space-between;
}

.Progress1 {
  height: 6px;
  border-radius: 3px 0 0 3px;
  background-color: #ff5757;
}

.Progress2 {
  height: 6px;
  border-radius: 0 3px 3px 0;
  background-color: #2873db;
}
