@import "styles/variables.scss";
@import "styles/mixsins";

.Tabs {
  margin-bottom: 10px;
}

.NoData {
  @include labelM;
  margin: 0;
}

.VotersContainer {
  display: flex;
  align-items: center;
  padding-top: 12px;
  @include border(border-top);
  margin: 10px 0;
  transition: $transition;

  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.VoterNumber {
  display: flex;
  flex-direction: column;
  margin-right: 10px;
  color: var(--font-color-primary);
  width: 10px;
}

.NameWrapper {
  display: flex;
  gap: 8px;
  align-items: center;
  width: calc(100% - 20px);
}

.VoterName {
  display: flex;
  flex-grow: 1;
  hyphens: auto;
  text-decoration: none;
  color: $blue-primary;
  font-size: $font-size-s;
  line-height: 140%;
  overflow-wrap: anywhere;
  width: calc(100% - 72px);
}

.VoterRating {
  font-weight: 600;
  font-size: $font-size-s-2;
  color: var(--font-color-primary);
  line-height: 140%;
  width: 32px;
  flex-shrink: 0;
  text-align: right;
}
