@import "styles/variables.scss";
@import "styles/mixsins";

.NoData {
  @include labelM;
  margin: 0;
}

.Item {
  display: flex;
  color: $black-primary;
  align-items: center;
  text-decoration: none;
  padding-top: 10px;
  @include border("border-top");
  transition: $transition;

  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
}

.ItemsContainer {
  .Item:first-child {
    border-top: none;
    padding-top: 0;
  }
}

.Icon {
  width: 40px;
  height: 40px;
  border: 1px solid var(--border-color-default);
  border-radius: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;

  img {
    width: 21px;
    height: 20px;
  }
}

.Name {
  display: flex;
  color: var(--font-color-primary);
  flex-direction: column;
  margin-left: 10px;
}

.Text {
  font-weight: 600;
  font-size: $font-size-s-2;
  line-height: $line-height-base;
}

.Time {
  font-size: $font-size-s-2;
  line-height: $line-height-base;
  color: $grey-primary;
}
