@import "styles/variables.scss";
.Header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 24px;
  gap: 16px;

  @media (max-width: 600px) {
    flex-direction: column;
    align-items: stretch;
    padding-bottom: 16px;

    div {
      align-self: end;
    }
  }
}

.Title {
  margin: 0;
  font-weight: 600;
  color: var(--font-color-primary);
  font-size: $font-size-l;
  line-height: $line-height-base;
}

.ShowMoreButton {
  margin-top: 6px;
}
