@import "styles/variables.scss";
@import "../../../../../styles/mixsins";

.TextAccent {
  color: $red-hover;
  font-weight: 500;
}

.Bold {
  font-weight: 600;
}

.Video {
  width: 100%;
}

.Link {
  text-decoration: none;
  color: $black-primary;
  text-transform: uppercase;
  font-size: $font-size-s-2;
  font-weight: 600;
  line-height: $line-height-base;

  &:hover {
    opacity: 0.8;
  }

  svg {
    transform: rotate(90deg);
    margin-bottom: -4px;
    width: 17px;
    height: auto;
  }
}
