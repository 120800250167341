@import "styles/variables.scss";
.Comment {
  padding-top: 16px;

  &:first-child {
    padding-top: 0;
    margin-top: 16px;
  }
}

.CommentLine {
  composes: Comment;
  padding-left: 26px;
  border-left: 1px solid var(--border-color-secondary);
}

.Text {
  font-size: $font-size-base;
  line-height: $line-height-l;
  color: var(--font-color-primary);
  margin: 8px 0 4px;
  overflow-wrap: break-word;
  word-break: break-word;
  white-space: pre-wrap;
  overflow: hidden;
}
