@import "styles/variables.scss";
$vote-panel-width: 98px;
$event-panel-gap: 28px;
$event-panel-gap-mobile: 10px;
$mobile-size: 540px;

.Root {
  padding: 20px 0 24px;
}

.Header {
  display: grid;
  grid-template: 1fr / auto 1fr auto;
  grid-template-areas: 'select date switcher';
  align-items: center;
  padding: 0 24px;
  justify-content: flex-start;
  gap: 20px;
  
  @media (max-width: $mobile-size) {
    padding: 0 16px;
    gap: 10px;
  }
}

.TournamentDropdown {
  grid-area: select;
}

.DropdownLabel {
  @media (max-width: $tablet-size) {
    max-width: 80px;
  }
  
/*  @media (max-width: $mobile-size) {
    max-width: 60px;
  }*/
}

.Date {
  grid-area: date;
  
  font-size: 14px;
  line-height: 150%;
  color: $grey-primary-7;
  white-space: nowrap;
}

.Switcher {
  grid-area: switcher;
  
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.EventList {
  transition: height 0.3s ease-in-out;
}

.First {
  padding: 32px 0;
  border-bottom: 1px solid var(--border-color-secondary);

  @media (max-width: $mobile-size) {
    padding: 21px 16px 0;
  }
}

.FirstItem {
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: $mobile-size) {
    justify-content: space-between;
    gap: 10px;
  }
}

.OpponentItem {
  width: calc(50% - 16px);
}

.FirstItemDescription {
  display: none;
  padding: 15px 0 16px;

  @media (max-width: $mobile-size) {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.Item {
  background: var(--background-color-card);

  height: 112px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid var(--border-color-secondary);
  padding: 24px 0;
  gap: $event-panel-gap;

  &:last-child {
    border-bottom: none;
  }

  &:nth-child(even) {
    background: var(--background-color-card);
  }

  @media (max-width: $mobile-size) {
    height: 87px;
    gap: $event-panel-gap-mobile;
  }
}

.MoreButton {
  background: #ffffff;
  border: 1px solid $grey-primary-4;
  border-radius: 7px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 30px 24px 0;
  cursor: pointer;
  
  transition: $transition;

  &::after {
    content: '';
    width: 16px;
    height: 16px;
    background: url(./arrow.svg) no-repeat center;
  }
  
  &:hover {
    transition: $transition;
    text-decoration: none;
    color: $blue-hover;
  }

  @media (max-width: $mobile-size) {
    margin: 20px 16px 0;
  }
}

.MainVote {
  font-weight: 500;
  background-color: var(--background-color-red-button-widgets);

&:hover {
  background-color: var(--background-color-button-widgets-vote-pari-hover);
}
}

.VoteButton {
  font-weight: 500;
  display: block;
  text-transform: uppercase;
  line-height: 16.5px;
  background-color: var(--background-color-red-button-widgets);
  height: unset;
  padding: 4px 10px;
  font-size: 11px;

  &:hover {
    background-color: var(--background-color-button-widgets-vote-pari-hover);
  }

  @media (max-width: $mobile-size) {
    display: none;
  }
}

.VoteButtonMobile {
  text-align: center;
  display: none;
  padding: 16px;

 button{
   background-color: var(--background-color-red-button-widgets);

   &:hover {
     background-color: var(--background-color-button-widgets-vote-pari-hover);
   }
 }

  @media (max-width: $mobile-size) {
    display: block;
  }
}

.Slider {
  display: none;
  width: 100vw;
  margin-top: 16px;
  overflow-x: auto;

  @media (max-width: $mobile-size) {
    display: flex;
    gap: 12px;
    
    padding: 0 16px;
  }
}

.SliderItem {
  display: flex;
  align-items: center;
  white-space: nowrap;
  height: 29px;
  border: 1px solid $grey-primary-4;
  border-radius: 7px;
  padding: 0 8px;
  font-size: 13px;
  line-height: 150%;
  cursor: pointer;

  &:first-child {
    max-resolution: 16px;
  }
}

.ActiveSliderItem {
  composes: SliderItem;

  background: $blue-primary;
  border: 1px solid $blue-primary;
  border-radius: 7px;
  color: #ffffff;
}

.ItemPercents {
  font-size: 12px;
  line-height: 130%;
  color: $grey-primary;
  margin-bottom: 8px;
  font-weight: 500;
}

.MainItemDescription {
  text-align: center;
  padding: 0 40px;

  @media (max-width: $mobile-size) {
    display: none;
  }
}

.ItemDescription {
  display: flex;
  flex-direction: column;
  align-items: center;
  
  text-align: center;
  max-width: $vote-panel-width;

  @media (max-width: $mobile-size) {
    display: none;
  }
}

.ItemProgress {
  margin-bottom: 4px;
}

.ItemOpponent {
  width: calc(((100% - $vote-panel-width ) / 2) - $event-panel-gap);
  margin: 0;
  flex-shrink: 0;

  @media (max-width: $mobile-size) {
    width: calc(50% - $event-panel-gap-mobile);
  }
}

.MobileValue {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.MobileProgressWrapper {
  display: flex;
  align-items: center;
  font-size: 12px;
  line-height: 130%;
  font-weight: 500;
}

.MobileProgress {
  width: 80px;
  margin: 0 8px;
}

.CommentWrapper {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.ItemValue {
  font-size: 13px;
  line-height: 150%;
  color: $positive-color;
  font-weight: 600;
}

.ItemValueDescription {
  font-size: 9px;
  line-height: 150%;
  color: $grey-primary;
  margin-bottom: 14px;

  @media (max-width: $mobile-size) {
    margin-bottom: 0;
  }
}

.Comments {
  display: flex;
  position: relative;
  margin-left: 22px;
  font-size: 15px;
  line-height: 22px;
  cursor: pointer;
  font-weight: 500;

  @media (max-width: $mobile-size) {
    font-size: 13px;
  }

  &::before {
    content: '';
    display: block;
    width: 22px;
    height: 22px;
    background: url('../../../../assets/icons/comment.svg') center no-repeat;
    background-size: contain;
  }
}
