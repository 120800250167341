@import "styles/variables.scss";
$mobile-size: 540px;

.Root {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
  gap: 10px;
}

.InverseRoot {
  composes: Root;

  flex-direction: row-reverse;

  & .Info {
    text-align: left;
    padding: 0 10px 0 0;
  }

  & .Name {
    flex-direction: row-reverse;
  }

  & .PercentInfo {
    flex-direction: row-reverse;
  }
}

.Info {
  text-align: right;
  flex-grow: 1;
  text-overflow: ellipsis;
  overflow: hidden;
  padding: 0 0 0 10px;
}

.Image {
  width: 64px;
  height: 64px;
  border-radius: 32px;
  border: 1px solid $black-color;
  background-size: cover;
  background-position: center;
  flex-shrink: 0;

  @media (max-width: $mobile-size) {
    width: 48px;
    height: 48px;
    border-radius: 24px;
  }
}

.Name {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 5px;
  
  &.Right {
    justify-content: flex-start;
  }
  
  & .Text {
    font-size: 15px;
    line-height: 150%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    font-weight: 500;
  }
  
  @media (max-width: $tablet-size) {
    & .Text {
      max-width: 100px;
      font-size: 14px;
    }
  }
  
  @media (max-width: $mobile-size) {
    & .Text {
      font-size: 12px;
      // max-width: 90px;
    }
  }
}

.Country {
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    height: 12px;
  }
}

.Score {
  font-size: 13px;
  line-height: 150%;
  color: $grey-primary-7;

  @media (max-width: $mobile-size) {
    display: none;
  }
}

.PercentInfo {
  @media (max-width: $mobile-size) {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}

.Percent {
  display: none;
  font-size: 12px;
  line-height: 150%;
  color: $grey-primary-7;

  @media (max-width: $mobile-size) {
    display: block;
    font-weight: 500;
  }
}

.Circle {
  display: none;
  width: 3px;
  height: 3px;
  margin: 0 6px;
  background: url(./circle.svg) no-repeat center;

  @media (max-width: $mobile-size) {
    display: block;
  }
}

.Coefficient {
  font-size: 13px;
  line-height: 150%;
  color: $blue-primary;
  font-weight: 600;
  
  @media (max-width: $mobile-size) {
    font-size: 12px;
  }
}
