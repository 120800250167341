@import "styles/variables.scss";
.title {
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 130%;
  color: var(--font-color-primary);
  box-sizing: border-box;
  @media (max-width: $mobile-size) {
    font-size: 28px;
  }
}
