@import "styles/variables.scss";
.Root {
  width: 100%;
  max-width: 1300px;
  margin: 40px auto;
  gap: 40px;

  @media (max-width: 842px) {
    margin: 0 0 40px;
  }
}

.Card {
}

.List {
  display: flex;
  align-items: center;
  max-width: 1018px;
  margin: 0 auto;
  padding: 24px;
  
  border-bottom: 1px solid var(--border-color-secondary);
}

.Item {
  color: var(--font-color-secondary);
  line-height: 150%;
  font-weight: 400;
  cursor: pointer;
}

.ActiveItem {
  composes: Item;
  color: var(--font-color-primary);
  font-weight: 500;
  line-height: 150%;
  border-bottom: 1px solid var(--border-color-active);
}

.Count {
  font-weight: 500;
  font-size: 13px;
  line-height: 24px;
  color: $blue-primary;
}

.Comments {
  padding: 32px 24px;
  max-width: 1018px;
  margin: 0 auto;
}
