@import "styles/variables.scss";
.Title {
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(270deg, $red-primary 0%, #ff6969 100%);
  border-radius: 10px 10px 0px 0px;
  height: 25px;
  font-size: 12px;
  line-height: 130%;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: $white-color;

  @media (max-width: $mobile-size) {
    height: 28px;
  }
}
