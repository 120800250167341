@import "styles/variables.scss";
@import "styles/mixsins";

.NoData {
  @include labelM;
}

.Item {
  display: flex;
  flex-direction: column;
  text-decoration: none;
  color: var(--font-color-primary);
  line-height: $line-height-base;
  padding-top: 6px;
  @include border("border-top");
}

.ItemsContainer {
  .Item:first-child {
    border-top: none;
    padding-top: 0;
  }
}

.NewsTitle {
  padding-left: 24px;
  margin: 0 0 5px 0;
  font-size: $font-size-s;
  @include linkActions;
  color: var(--font-color-primary);
  background: url('./fire.svg') no-repeat 0 2px;
}

.NewsCommentsCount {
  font-size: $font-size-s-2;
  line-height: $line-height-base;
  font-weight: 600;
  margin-right: 6px;
}

.Time {
  display: flex;
  align-items: center;
  font-size: $font-size-s-2;
  line-height: $line-height-base;
  color: $grey-primary;

  & svg {
    margin-right: 6px;
  }
}

.Updating {
  padding-left: 6px;
  font-size: 12px;
  line-height: 130%;
  color: $grey-primary;
  font-style: italic;
}
